<template>
  <v-card class="white" elevation="1" fluid v-if="getRole !== null">
    <v-card-title>
      <v-btn
        icon
        @click="
          $route.path.includes('All')
            ? $router.replace('/')
            : $router.replace(`/user/${$route.params.uid}`)
        "
      >
        <v-icon small>fa-arrow-left</v-icon>
      </v-btn>
      <small>
        Document Details
        <pre
          class="
            grey
            lighten-2
            px-2
          ">{{ $route.params.collection }}/{{ $route.params.doc }} <p v-if="cardId"></p></pre>
      </small>

      <v-spacer></v-spacer>
      <v-btn x-small depressed @click="openAll = !openAll">
        {{ openAll ? 'Collapse' : 'Expand' }} All
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-img
      v-if="screenshotPath"
      max-width="404"
      max-height="260"
      elevation="1"
      style="border-radius: 0px; border: 0px solid grey; margin: 20px"
      :src="screenshotPath"
    >
    </v-img>

    <v-treeview :items="items" hoverable :open-all="!openAll"></v-treeview>
  </v-card>
  <div v-else-if="getRole === null" class="row">
    <div class="col">You are not an Authorized user</div>
  </div>
  <div v-else class="row">
    <div class="col text-center">Authenticating...</div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'DocDetails',
  data() {
    return {
      items: [],
      openAll: true,
      cardId: '',
      cardDetail: false,
      screenshotPath: null,
    };
  },
  computed: {
    ...mapGetters(['getDocDetails', 'getRole', 'getSearchText']),
  },
  watch: {
    '$route.params.collection'(newCol, prevCol) {
      if (newCol !== prevCol) this._fetchDocDetails();
    },
    '$route.params.doc'(newDoc, prevDoc) {
      if (newDoc !== prevDoc) this._fetchDocDetails();
    },
  },
  methods: {
    ...mapActions(['fetchDocDetails', 'fetchCard']),
    handleClick() {},
    treeFormatter(key, index, value) {
      if (key == 'cardId') {
        this.cardId = value;
      }
      const item = {
        id: index,
        name:
          key +
          ': ' +
          (value && typeof value === 'object' && Object.keys(value).length
            ? `(${Object.keys(value).length})`
            : key == 'createDate' || key == 'updateDate'
            ? this.formatDate(value)
            : value),
      };
      if (value && typeof value === 'object' && Object.keys(value).length) {
        item['children'] = Object.keys(value).map((el, ind) => {
          return this.treeFormatter(el, index + ind, value[el]);
        });
      }
      return item;
    },
    formatDate(value) {
      if (value) {
        return format(value, 'dd MMM yyyy hh:mm a', new Date());
      }
      return value;
    },
    async _fetchDocDetails() {
      this.screenshotPath = null;
      if (this.$route.params.collection && this.$route.params.doc) {
        const { collection, doc } = this.$route.params;
        await this.fetchDocDetails({
          collection,
          doc,
        });
        this.$nextTick(() => {
          if (this.getDocDetails) {
            const first = { ...this.getDocDetails };
            if (first.cardId) {
              this.fetchCard(first.cardId).then(
                (cardData) => (this.screenshotPath = cardData.screenshotPath)
              );
            } else {
              this.screenshotPath = first.screenshotPath;
            }
            this.items = Object.keys(first).map((el, index) => {
              return this.treeFormatter(el, index, first[el]);
            });
          }
        });
      }
    },
  },
  mounted() {
    this._fetchDocDetails();
  },
};
</script>
<style lang="scss">
.min-h-100 {
  min-height: 100vh;
}
</style>
